<template>
  <q-card flat class="search-widget bg-transparent">
    <q-tabs
      v-model="searchTab"
      align="left"
      narrow-indicator
      indicator-color="secondary"
      dense
    >
      <q-tab name="make_model" label="Make/Model" :ripple="false" />
      <q-tab name="body_type" label="Body Type" :ripple="false" />
    </q-tabs>

    <q-tab-panels v-model="searchTab" animated class="bg-transparent">
      <q-tab-panel
        name="make_model"
        class="search-widget__side-panel"
        style="overflow: hidden !important;"
      >
        <q-form @submit="submitSearch('makeModel')">
          <q-card class="search-widget__inputs-container">
            <q-select
              v-if="windowWidth > 1025"
              :borderless="windowWidth > 1025"
              :filled="windowWidth <= 1025"
              v-model="searchFilterData.models.condition"
              :options="conditionOptions"
              map-options
              emit-value
              label="Condition"
              class="full-width no-wrap ellipsis"
            />
            <q-separator
              v-if="windowWidth > 1025"
              vertical
              inset
              class="q-mx-md"
            />
            <q-select
              :borderless="windowWidth > 1025"
              :filled="windowWidth <= 1025"
              v-model="searchFilterData.models.make"
              :options="makeOptions"
              map-options
              emit-value
              label="Make"
              class="full-width"
            >
              <template v-slot:selected-item="scope">
                <span class="ellipsis">{{ scope.opt.label }}</span>
              </template>
            </q-select>
            <q-separator
              v-if="windowWidth > 1025"
              vertical
              inset
              class="q-mx-md"
            />
            <q-select
              :borderless="windowWidth > 1025"
              :filled="windowWidth <= 1025"
              label="Model"
              v-model="searchFilterData.models.model"
              :options="modelOptions"
              option-value="value"
              option-label="label"
              option-disable="disable"
              emit-value
              map-options
              class="full-width"
            >
              <template v-slot:selected-item="scope">
                <span class="ellipsis">{{ scope.opt.label }}</span>
              </template>
            </q-select>
            <q-separator
              v-if="windowWidth > 1025"
              vertical
              inset
              class="q-mx-md"
            />
            <q-input
              :borderless="windowWidth > 1025"
              :filled="windowWidth <= 1025"
              v-model="postalCode"
              label="Zip Code"
              inputmode="numeric"
              pattern="[0-9]*"
              mask="#####"
              :error="postalCodeError != null"
              class="full-width q-pb-none"
            >
              <template v-slot:prepend>
                <q-icon name="sym_r_place" />
              </template>
              <template v-slot:append>
                <q-btn
                  v-if="windowWidth <= 1025"
                  dense
                  label="Go"
                  type="submit"
                  color="secondary"
                  class="q-my-sm q-ml-sm q-px-xs"
                  :disable="disableSearch"
                  :loading="disableSearch"
                  @click="submitSearch('makeModel')"
                />
              </template>
            </q-input>
            <q-btn
              v-if="windowWidth > 1025"
              label="Go"
              type="submit"
              color="secondary"
              class="q-my-sm q-ml-md"
              :disable="disableSearch"
              :loading="disableSearch"
            />
          </q-card>
        </q-form>
      </q-tab-panel>
      <q-tab-panel
        name="body_type"
        class="search-widget__side-panel"
        style="overflow: hidden !important;"
      >
        <q-form @submit="submitSearch('bodyType')">
          <q-card flat class="search-widget__inputs-container">
            <q-select
              v-if="windowWidth > 1025"
              :borderless="windowWidth > 1025"
              :filled="windowWidth <= 1025"
              v-model="searchFilterData.models.condition"
              :options="conditionOptions"
              map-options
              emit-value
              label="Condition"
              class="full-width"
            >
              <template v-slot:selected-item="scope">
                <span class="ellipsis">{{ scope.opt.label }}</span>
              </template>
            </q-select>
            <q-separator
              v-if="windowWidth > 1025"
              vertical
              inset
              class="q-mx-md"
            />
            <q-select
              :borderless="windowWidth > 1025"
              :filled="windowWidth <= 1025"
              v-model="searchFilterData.models.bodyType"
              :options="bodyTypeOptions"
              map-options
              emit-value
              label="Body Type"
              class="full-width"
              :style="[windowWidth <= 1025 ? { 'grid-column': '1/-1' } : {}]"
            >
              <template v-slot:selected-item="scope">
                <span class="ellipsis">{{ scope.opt.label }}</span>
              </template>
            </q-select>
            <q-separator
              v-if="windowWidth > 1025"
              vertical
              inset
              class="q-mx-md"
            />
            <q-input
              :borderless="windowWidth > 1025"
              :filled="windowWidth <= 1025"
              v-model="postalCode"
              label="Zip Code"
              inputmode="numeric"
              pattern="[0-9]*"
              mask="#####"
              :error="postalCodeError != null"
              class="full-width q-pb-none"
            >
              <template v-slot:prepend>
                <q-icon name="sym_r_place" />
              </template>
              <template v-slot:append>
                <q-btn
                  v-if="windowWidth <= 1025"
                  dense
                  label="Go"
                  type="submit"
                  color="secondary"
                  class="q-my-sm q-ml-sm q-px-xs"
                  :disable="disableSearch"
                  :loading="disableSearch"
                  @click="submitSearch('bodyType')"
                />
              </template>
            </q-input>
            <q-btn
              v-if="windowWidth > 1025"
              label="Go"
              type="submit"
              color="secondary"
              class="q-my-sm q-ml-md"
              :disable="disableSearch"
              :loading="disableSearch"
            />
          </q-card>
        </q-form>
      </q-tab-panel>
    </q-tab-panels>
  </q-card>
</template>

<script>
export default {
  name: "SearchPanel",
  data() {
    return {
      searchTab: "make_model",
      disableSearch: false,
      postalCode: this.$store.state.search.location.postalCode,
      postalCodeError: null
    };
  },
  computed: {
    searchFilterData() {
      return this.$store.state.search.filter;
    },
    makeOptions() {
      return this.$store.getters["vehicleMakeModels/makeOptionsList"](true);
    },
    modelOptions() {
      let modelOptions = [{ label: "All Models", value: [] }];
      if (this.searchFilterData.models.make.length > 0) {
        this.searchFilterData.models.make.forEach(makeId => {
          this.$store.state.vehicleMakeModels.makeIdModelIdsMap[
            makeId.toString()
          ].forEach(modelId => {
            modelOptions.push({
              label: this.$store.state.vehicleMakeModels.modelIdNameMap[
                modelId
              ],
              value: [modelId]
            });
          });
        });
      }
      return modelOptions;
    },
    conditionOptions() {
      return [
        { label: "All Conditions", value: [] },
        { label: "Used", value: ["used"] },
        { label: "New", value: ["new"] }
      ];
    },
    bodyTypeOptions() {
      let bodyTypeOptions = [{ label: "All Body Types", value: [] }];
      this.searchFilterData.options.bodyType.forEach(option => {
        let newOption = {
          label: option.label,
          value: [option.value]
        };
        bodyTypeOptions.push(newOption);
      });
      return bodyTypeOptions;
    }
  },
  mounted() {},
  methods: {
    submitSearch(type) {
      this.disableSearch = true;

      this.$store
        .dispatch("geolocation/VALIDATE_POSTAL_CODE", this.postalCode)
        .then(locationData => {
          this.postalCodeError = null;

          this.$store.commit("search/SET_LOCATION", {
            postalCode: this.postalCode,
            city: locationData["city"],
            state: locationData["state"]
          });

          if (type === "makeModel") {
            this.searchFilterData.models.bodyType = [];

            if (this.searchFilterData.models.model.length > 0) {
              // Since the vehicle search filtering relies on existent makeId OR modelId, but not both for same make + model.
              // Example, &make=1&model=1 only returns &model=1. So &make is meaningless and misleading.
              //this.$store.commit("vehicles/REMOVE_SEARCH_FILTER_MODEL_VALUE", { modelKey: "make", value: this.searchFilterData.models.make[0] });
              this.searchFilterData.models.make = [];
            }
          } else if (type === "bodyType") {
            this.searchFilterData.models.make = [];
            this.searchFilterData.models.model = [];
          }

          this.$store.commit("search/SET_FILTER_MODELS_ACTIVE");
          this.$store.commit("search/SET_LOADING");

          this.$store.state.search.sort.model =
            "advanced.popular_on_carscience";

          this.$router.push({ path: "/search" });
        })
        .catch(error => {
          this.postalCodeError = error;
          this.disableSearch = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.search-widget {
  width: 100%;

  .search-widget__side-panel {
    padding: $space-md 0;
  }

  .search-widget__inputs-container {
    @include display-flex(row, flex-start, items-center);
    padding: 0px $space-md;
    background: color(background, light);

    @include screen-md {
      background: color(background);
      padding: $space-md;
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-gap: $space-md;

      .q-field {
        &:nth-child(even) {
          grid-column: 0/1;
        }
        &:nth-child(odd) {
          grid-column: 1/2;
        }
        &:last-child {
          grid-column: 1/-1;
        }
      }
    }
  }
}
</style>
