<template>
  <div class="lander">
    <section
      class="lander__cta"
      :style="{
        background:
          'linear-gradient(rgba(0, 0, 0, 1.0), rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.70), #131415), url(' +
          ctaCoverPhoto +
          ') no-repeat center center fixed'
      }"
      style="background-size: cover;"
    >
      <div class="lander__cta-content">
        <div class="lander__cta-header-container">
          <h2 v-if="windowWidth > 1025" class="lander__cta-header q-mb-sm">
            {{ ctaHeader }}
          </h2>
          <h3 v-else class="lander__cta-header q-mb-sm">
            {{ ctaHeader }}
          </h3>
          <component
            :is="windowWidth > 1025 ? 'h5' : 'h6'"
            class="lander__cta-subheader q-mb-none"
          >
            {{ ctaSubheaderPrefix }}
            {{ formatNumber(totalVehicles, true, 1) }} listings to find the best
            matches for you.
          </component>
        </div>

        <div class="lander__cta-search-container">
          <SearchPanel class="full-width" />
        </div>
      </div>
    </section>

    <div class="lander__content q-gutter-y-lg">
      <div
        v-for="(publisherComponent, i) in publisherComponents"
        :key="publisherComponent.key + '_publisherComponent'"
        :class="{
          'q-mt-none': i === 0 || publisherComponent.hide,
          'q-pb-md': !publisherComponent.hide
        }"
      >
        <VehicleListingsPublisherComponentSection
          :publisher-component-data="publisherComponent"
        />
      </div>
    </div>
  </div>
</template>

<script>
import LanderCoverPhoto from "@/assets/images/lander-cover.jpg";
import SearchPanel from "@/components/SearchPanel.vue";
import axios from "axios";
import VehicleListingsPublisherComponentSection from "@/components/UI/VehicleListingsPublisherComponentSection.vue";

export default {
  name: "Lander",
  components: { VehicleListingsPublisherComponentSection, SearchPanel },
  data() {
    return {
      ctaCoverPhoto: LanderCoverPhoto,
      ctaHeader: "A Personalized Way To Shop For Cars",
      ctaSubheaderPrefix: "Carscience is analyzing ",
      totalVehicles: 100000,
      totalVehiclesCounterInterval: null,
      totalVehiclesCounterLimit: 1500000,
      publisherComponents: [
        {
          title: "Best Matches For You",
          component: "jfpc-vehicle-listings-visitor-recommended",
          hide: !window.localStorage.getItem("jfpc-clicked-vehicles"),
          hideHeader: true,
          seeMoreAction: () => {},
          key: Math.random() * 100000
        },
        {
          title: "Recently Viewed",
          component: "jfpc-vehicle-listings-visitor-recently-clicked",
          hide: false,
          hideHeader: true,
          seeMoreAction: () => {},
          key: Math.random() * 100000
        },
        {
          title: "Because You Viewed",
          component:
            "jfpc-vehicle-listings-visitor-recently-clicked-make-model",
          hide: false,
          hideHeader: true,
          seeMoreAction: () => {},
          key: Math.random() * 100000
        },
        {
          title: "Recent Price Drops",
          component: "jfpc-vehicle-listings-recent-price-drops",
          hide: false,
          hideHeader: true,
          seeMoreAction: () =>
            (this.$store.state.search.sort.model =
              "advanced.recent_price_drops"),
          key: Math.random() * 100000
        },
        {
          title: "Popular On CarScience",
          component: "jfpc-vehicle-listings-most-clicked",
          hide: false,
          hideHeader: true,
          seeMoreAction: () =>
            (this.$store.state.search.sort.model =
              "advanced.popular_on_carscience"),
          key: Math.random() * 100000
        },
        {
          title: "Most Searched",
          component: "jfpc-vehicle-listings-most-viewed",
          hide: false,
          hideHeader: true,
          seeMoreAction: () =>
            (this.$store.state.search.sort.model = "advanced.most_searched"),
          key: Math.random() * 100000
        },
        {
          title: "Newly Added",
          component: "jfpc-vehicle-listings-newly-added",
          hide: false,
          hideHeader: true,
          seeMoreAction: () =>
            (this.$store.state.search.sort.model = "classic.newest_listed"),
          key: Math.random() * 100000
        }
      ]
    };
  },
  beforeUnmount() {
    this.clearTotalVehiclesCounterInterval();
  },
  mounted() {
    this.$store.commit("search/RESET_FILTERS");
    this.$store.commit("search/RESET_PAGINATION");
    this.$store.commit("search/RESET_KEYWORD");
    
    if(window.sessionStorage.getItem("jfpc-clicked-vehicles-initial-value") === null) {
      window.sessionStorage.setItem("jfpc-clicked-vehicles-initial-value", (window.localStorage.getItem("jfpc-clicked-vehicles") ? "1" : "0"));
    }
    
    if(window.sessionStorage.getItem("jfpc-clicked-vehicles-initial-value") === "1") {
      let leadFormUserData = window.localStorage.getItem(
          window.location.hostname + "-leadFormUserData"
      );
      if (leadFormUserData) {
        leadFormUserData = JSON.parse(leadFormUserData);
        this.ctaHeader = "Welcome Back, " + leadFormUserData.firstName;
        this.ctaSubheaderPrefix = "While you were away we analyzed ";
      } else if (window.localStorage.getItem("jfpc-clicked-vehicles")) {
        this.ctaHeader = "Welcome Back to CarScience";
        this.ctaSubheaderPrefix = "While you were away we analyzed ";
      }
    }

    this.initTotalVehicles();
  },
  methods: {
    initTotalVehicles() {
      this.setTotalVehiclesCounterInterval();

      axios
        .post(
          "https://api-v2.jumpfeed.com/network/publisher/products/vehicles",
          {
            pagination: {
              page: 1,
              per_page: 1
            }
          }
        )
        .then(response => {
          let totalVehicles = response.data.data.totals.results;
          this.totalVehiclesCounterLimit =
            totalVehicles < 1000000
              ? Math.floor(Math.random() * (1801031 - 1550021 + 1) + 1550021)
              : totalVehicles;
          if (!this.totalVehiclesCounterInterval) {
            this.setTotalVehiclesCounterInterval();
          }
        });
    },
    setTotalVehiclesCounterInterval() {
      this.totalVehiclesCounterInterval = setInterval(
        () =>
          this.totalVehicles > this.totalVehiclesCounterLimit
            ? this.clearTotalVehiclesCounterInterval()
            : this.totalVehicles > 1000000
            ? (this.totalVehicles += 4221)
            : (this.totalVehicles += 1921),
        1
      );
    },
    clearTotalVehiclesCounterInterval() {
      clearInterval(this.totalVehiclesCounterInterval);
      this.totalVehiclesCounterInterval = null;
    }
  }
};
</script>

<style scoped lang="scss">
.lander {
  .lander__cta {
    border-bottom-left-radius: 1px;
    border-bottom-right-radius: 1px;
    position: relative;

    @include screen-custom(1200) {
      height: auto;
    }

    .lander__cta-content {
      @include display-flex(column, center, flex-start);
      @include contain-content-width;
      padding: custom-space(16) $space-xl custom-space(40) $space-xl;

      @include screen-custom(1200) {
        height: 100%;
      }

      @include screen-md {
        padding: custom-space(12) $space-lg custom-space(32) $space-lg;
      }

      .lander__cta-header-container {
        position: relative;
        z-index: 1;
        width: 100%;
        color: color(text);
        text-align: center;
        margin-bottom: $space-md;

        .lander__cta-header {
          color: color(text);
        }

        .lander__cta-subheader {
          font-weight: 400;
          color: color(text, light);
        }
      }

      .lander__cta-search-container {
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;

        @include screen-md {
          max-width: none;
        }
      }
    }
  }

  .lander__content {
    @include contain-content-width;
    padding: 0 $space-xl;
    margin-bottom: $space-xl;
    margin-top: -#{custom-space(28)};
    position: relative;

    @include screen-md {
      padding: 0 $space-lg;
    }
  }
}
</style>
